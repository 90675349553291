import { useCallback } from "react";

import { Flex, Typography } from "@nestoca/ui";
import { formatCurrency, formatDate } from "@shared/utils";
import { useTranslation } from "react-i18next";

type Props = {
  heading: string;
  value: string | number;
  type?: "currency" | "date";
};
export const DetailsRecord = ({ heading, value, type }: Props) => {
  const {
    i18n: { language },
  } = useTranslation();

  const valueToDisplay = useCallback(() => {
    switch (type) {
      case "currency":
        return formatCurrency(value as number, language);
      case "date":
        return formatDate(value as string, language);
      default:
        return value;
    }
  }, [type, value, language]);

  return (
    <Flex direction="column">
      <Typography size={0} weight={7}>
        {heading}
      </Typography>
      {value ? <Typography size={0}>{valueToDisplay()}</Typography> : "-"}
    </Flex>
  );
};
