import { Flex } from "@nestoca/ui";
import { useGetApplication } from "@shared/api/hooks/applications";
import { Application } from "@shared/constants";
import { useTranslation } from "react-i18next";
import { BsCalendar2Date } from "react-icons/bs";

import { DetailsHeading } from "./details-heading";
import { DetailsRecord } from "./details-record";
import styles from "./details.module.scss";

type Props = {
  applicationId: number;
};

export const ImportantDates = ({ applicationId }: Props) => {
  const { data: application } = useGetApplication({ id: applicationId });
  const { t } = useTranslation("applications");

  const { mortgage, created } = application as Application;

  return (
    <Flex
      direction="column"
      className={styles.block}
      gap={4}
      data-testid="details-block"
    >
      <DetailsHeading
        icon={<BsCalendar2Date size={20} />}
        text={t("importantDates", { ns: "common" })}
      />
      <DetailsRecord
        heading={t("details.applicationStartDate")}
        value={created}
        type="date"
      />
      <DetailsRecord
        heading={t("details.closingDate")}
        value={mortgage?.closingDate || ""}
      />
    </Flex>
  );
};
