import { Flex } from "@nestoca/ui";
import { useGetApplication } from "@shared/api/hooks/applications";
import { Application } from "@shared/constants";
import { useTranslation } from "react-i18next";
import { BsCardList } from "react-icons/bs";

import { DetailsHeading } from "./details-heading";
import { DetailsRecord } from "./details-record";
import styles from "./details.module.scss";

type Props = {
  applicationId: number;
};

export const MoreDetails = ({ applicationId }: Props) => {
  const { data: application } = useGetApplication({ id: applicationId });
  const { t } = useTranslation("applications");

  const { mortgage, property } = application as Application;

  const propertyAmount =
    application?.type === "NEW"
      ? property?.purchasePrice
      : property?.estimatedPropertyValue;

  return (
    <Flex
      direction="column"
      className={styles.block}
      gap={4}
      data-testid="details-block"
    >
      <DetailsHeading
        icon={<BsCardList />}
        text={t("moreDetails", { ns: "common" })}
      />
      <DetailsRecord
        heading={
          application?.type === "NEW"
            ? t("details.purchasePrice")
            : t("details.propertyValue")
        }
        value={propertyAmount || 0}
        type="currency"
      />
      <DetailsRecord
        heading={t("details.mortgageAmount")}
        value={mortgage?.mortgageAmountPremiumsIncluded || 0}
        type="currency"
      />
    </Flex>
  );
};
