import { PropsWithChildren, useCallback } from "react";

import { ButtonIcon, Flex, Typography } from "@nestoca/ui";
import { TOAST_AUTOCLOSE_DELAY_IN_MS } from "@shared/constants";
import { capitalizeFirstLetters } from "@shared/utils";
import { useTranslation } from "react-i18next";
import { BsCheck } from "react-icons/bs";
import { IoCopyOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import useClipboard from "react-use-clipboard";

import styles from "./details.module.scss";

type Props = {
  role: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  withIcon?: boolean;
};

export const PersonContact = ({
  role,
  firstName,
  lastName,
  email,
  phone,
  withIcon = false,
}: Props) => {
  const { t } = useTranslation("common");

  const [isCopied, setCopied] = useClipboard(String(email), {
    // `isCopied` will go back to `false` after 1000ms.
    successDuration: 1000,
  });
  const onClick = useCallback(() => {
    toast(t("copiedToClipboard.email.default", { ns: "applications" }), {
      type: "success",
      autoClose: TOAST_AUTOCLOSE_DELAY_IN_MS,
    });
  }, [t]);

  const applicantInitials = capitalizeFirstLetters(t, firstName, lastName);

  return (
    <Flex align="center" gap={3}>
      {withIcon && (
        <Flex className={styles["contact__icon"]}>{applicantInitials}</Flex>
      )}
      <Flex direction="column">
        <Typography weight={7} size={0}>
          {role}
        </Typography>
        <Typography size={0}>{`${firstName} ${lastName}`}</Typography>
        <Flex wrap="wrap">
          <Flex className={styles["contact__email"]} gap={1}>
            <Typography size={0} className={styles.truncate}>
              {email}
            </Typography>
            <ButtonIcon
              aria-label="copy"
              icon={
                isCopied ? (
                  <BsCheck color="var( --color-status-success-foreground)" />
                ) : (
                  <IoCopyOutline color={"var(--color-primary-b-500)"} />
                )
              }
              backgroundColor="transparent"
              size="small"
              className={styles["contact__button"]}
              onClick={() => {
                setCopied();
                onClick();
              }}
            />
          </Flex>
          <Typography size={0}>{phone}</Typography>
        </Flex>
      </Flex>
    </Flex>
  );
};

interface EmptyPersonContactProps {
  role: string;
}

export const EmptyPersonContact = ({
  role,
  children,
}: PropsWithChildren<EmptyPersonContactProps>) => {
  return (
    <Flex align="center" gap={3}>
      <Flex direction="column">
        <Typography weight={7} size={0}>
          {role}
        </Typography>
        <Flex wrap="wrap">{children}</Flex>
      </Flex>
    </Flex>
  );
};
