import { useQuery } from "@tanstack/react-query";

import { getExternalBrokerBDM } from "../../external-brokers";
import { keyFactory } from "../utils";

import type { ExternalBrokerBDM } from "@shared/constants";

const externalBrokerKeys = keyFactory("external-broker");

// return `{ data: ExternalBrokerBDM | null | undefined, ...}`
// the data is null if the broker doesn't have a assigned BDM and
// the BE request return a 204 status code
// `useQuery` `queryFn` cannot be `undefined` so we use `null` instead
// because `useQuery` will return `data: undefined` when the query is loading etc.
// https://github.com/TanStack/query/discussions/6380#discussioncomment-7573695
export const useGetExternalBrokerBDM = <TResult = ExternalBrokerBDM | null>(
  accountId?: number,
  select?: (data: ExternalBrokerBDM | null) => TResult
) => {
  return useQuery({
    queryKey: externalBrokerKeys.detail({ id: accountId, child: "bdm" }),
    // Using the `enabled` option to prevent the query from running if `accountId` is falsy
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: ({ signal }) => getExternalBrokerBDM(accountId!, { signal }),
    enabled: !!accountId,
    select,
  });
};
