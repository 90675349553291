import { PropsWithChildren } from "react";

import { Flex, Typography } from "@nestoca/ui";

type Props = {
  icon: JSX.Element;
  text: string;
};

export const DetailsHeading = ({
  icon,
  text,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Flex justify="between">
      <Flex gap={3} align="center">
        {icon}
        <Typography weight={7}>{text}</Typography>
      </Flex>
      {children}
    </Flex>
  );
};
