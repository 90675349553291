import { api } from "@shared/api/client";

import type { ExternalBrokerBDM } from "@shared/constants";

export const getExternalBrokerBDM = async (
  accountId: number,
  { signal }: { signal?: AbortSignal } = {}
) => {
  const { data, status } = await api.get<ExternalBrokerBDM>(
    `external-brokers/${accountId}/bdm`,
    { signal }
  );

  if (status === 204) {
    return null;
  }

  return data;
};
