import { Box, Flex, Typography } from "@nestoca/ui";
import { useGetAccount } from "@shared/api/hooks/account";
import { useGetAssignedRepresentative } from "@shared/api/hooks/applications";
import { useGetExternalBrokerBDM } from "@shared/api/hooks/external-broker";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { BsPeople } from "react-icons/bs";
import { BsArrowUpSquare } from "react-icons/bs";

import { DetailsHeading } from "./details-heading";
import styles from "./details.module.scss";
import { EmptyPersonContact, PersonContact } from "./person-contact";

type Props = {
  applicationId: number;
};

export const YourTeam = ({ applicationId }: Props) => {
  const { t } = useTranslation("common");
  const { data: account } = useGetAccount();

  const { data: assignedRepresentative } =
    useGetAssignedRepresentative(applicationId);

  const { data: bdm } = useGetExternalBrokerBDM(account?.id);

  // If we are getting Karim as default from Salesforce,
  // we need to set the assigned representative to none
  const hasAssignedRepresentative = !!(
    assignedRepresentative &&
    assignedRepresentative.id &&
    assignedRepresentative?.firstName !== "Karim" &&
    assignedRepresentative?.lastName !== "Benabdallah"
  );

  const emails = [];
  if (hasAssignedRepresentative) {
    emails.push(assignedRepresentative?.email);
  }
  if (bdm) {
    emails.push(bdm?.email);
  }
  const mailto = emails.join(",");

  return (
    <Flex
      direction="column"
      className={styles.block}
      gap={4}
      data-testid="details-block"
    >
      <DetailsHeading icon={<BsPeople />} text={t("yourTeam")}>
        {mailto.length >= 1 && (
          <Link href={`mailto:${mailto}`}>
            <Flex gap={2} className={styles["escalate-link"]}>
              <Box>{t("escalateToTeam", { ns: "applications" })}</Box>
              <BsArrowUpSquare size={16} />
            </Flex>
          </Link>
        )}
      </DetailsHeading>
      <Flex gap={3} direction="column">
        {hasAssignedRepresentative && (
          <PersonContact
            role={t("underwriter")}
            firstName={assignedRepresentative.firstName}
            lastName={assignedRepresentative.lastName}
            email={assignedRepresentative.email}
            phone={assignedRepresentative.phone}
            withIcon
          />
        )}
        {!hasAssignedRepresentative && (
          <EmptyPersonContact role={t("underwriter")}>
            <Typography size={0}>{t("noAssignedUnderwriter")}</Typography>
          </EmptyPersonContact>
        )}
        {bdm && (
          <PersonContact
            // eslint-disable-next-line jsx-a11y/aria-role
            role="BDM"
            firstName={bdm.firstName}
            lastName={bdm.lastName}
            email={bdm.email}
            phone={bdm.phone}
            withIcon
          />
        )}
        {!bdm && (
          <EmptyPersonContact
            // eslint-disable-next-line jsx-a11y/aria-role
            role="BDM"
          >
            <Typography size={0}>{t("noAssignedBDM")}</Typography>
          </EmptyPersonContact>
        )}
      </Flex>
    </Flex>
  );
};
