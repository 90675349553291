import { Fragment, useState } from "react";

import { Button, Grid } from "@nestoca/ui";
import { useTranslation } from "react-i18next";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

import { Applicant } from "./applicant";
import { CreditRecord } from "./credit-record";
import styles from "./details.module.scss";
import { ImportantDates } from "./important-dates";
import { MoreDetails } from "./more-details";
import { Product } from "./product";
import { YourTeam } from "./your-team";

type Props = {
  applicationId: number;
};

export const Details = ({ applicationId }: Props) => {
  const { t } = useTranslation("common");

  const [showMore, setShowMore] = useState(false);
  return (
    <Fragment>
      <Grid gap={3} className={styles.details}>
        <Applicant applicationId={applicationId} />
        <YourTeam applicationId={applicationId} />
        <Product applicationId={applicationId} />
        {showMore && (
          <Fragment>
            <MoreDetails applicationId={applicationId} />
            <ImportantDates applicationId={applicationId} />
            <CreditRecord applicationId={applicationId} />
          </Fragment>
        )}
      </Grid>
      <Button
        variant="secondary"
        size="small"
        onClick={() => setShowMore(!showMore)}
        rightIcon={
          showMore ? <BsChevronUp size={20} /> : <BsChevronDown size={20} />
        }
        className={styles["more-button"]}
      >
        {showMore ? t("showLess") : t("showMore")}
      </Button>
    </Fragment>
  );
};
