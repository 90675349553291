import { Flex } from "@nestoca/ui";
import {
  useGetCoApplicants,
  useGetMainApplicant,
} from "@shared/api/hooks/applications";
import { useTranslation } from "react-i18next";
import { IoPersonOutline } from "react-icons/io5";

import { DetailsHeading } from "./details-heading";
import styles from "./details.module.scss";
import { PersonContact } from "./person-contact";

type Props = {
  applicationId: number;
};

export const Applicant = ({ applicationId }: Props) => {
  const { data: mainApplicant } = useGetMainApplicant(applicationId);
  const { data: coApplicants } = useGetCoApplicants(applicationId);
  const { t } = useTranslation("common");

  const coApplicantsInfo = Object.values(coApplicants || []).map(
    (coApplicant) => {
      return {
        role: t(coApplicant.guarantor ? "guarantor" : "coApplicant"),
        firstName: coApplicant.firstName || "",
        lastName: coApplicant.lastName || "",
        email: coApplicant.email,
        phone: coApplicant.phone,
      };
    }
  );

  const applicants = [
    {
      role: t(mainApplicant?.guarantor ? "guarantor" : "mainApplicant"),
      firstName: mainApplicant?.firstName || "",
      lastName: mainApplicant?.lastName || "",
      email: mainApplicant?.email || "",
      phone: mainApplicant?.phone || "",
    },
    ...coApplicantsInfo,
  ];

  return (
    <Flex direction="column" className={styles.block} gap={4}>
      <DetailsHeading icon={<IoPersonOutline />} text={t("applicant")} />
      <Flex gap={3} direction="column">
        {applicants.map((applicant) => {
          return <PersonContact {...applicant} key={applicant.email} />;
        })}
      </Flex>
    </Flex>
  );
};
