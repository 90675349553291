import { Flex, Typography } from "@nestoca/ui";
import {
  useGetCoApplicants,
  useGetMainApplicant,
} from "@shared/api/hooks/applications";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { BsCreditCard2Front } from "react-icons/bs";
import { IoWarningOutline } from "react-icons/io5";

import { DetailsHeading } from "./details-heading";
import styles from "./details.module.scss";

type Props = {
  applicationId: number;
};

export const CreditRecord = ({ applicationId }: Props) => {
  const { t } = useTranslation("common");
  const { data: mainApplicant } = useGetMainApplicant(applicationId);
  const { data: coApplicants = [] } = useGetCoApplicants(applicationId);

  const allApplicants = mainApplicant
    ? coApplicants.toSpliced(0, 0, mainApplicant)
    : coApplicants;

  const applicants = allApplicants.map((applicant) => {
    return {
      creditReport: applicant.creditReport,
      firstName: applicant.firstName || "",
      lastName: applicant.lastName || "",
      applicantId: applicant.applicantId,
    };
  });

  return (
    <Flex
      direction="column"
      className={styles.block}
      gap={4}
      data-testid="details-block"
    >
      <DetailsHeading
        icon={<BsCreditCard2Front size={20} />}
        text={t("creditRecord")}
      />
      {applicants.map((applicant) => (
        <Flex gap={3} align="center">
          <Flex
            className={clsx(styles["credit-record__score"], {
              [styles["credit-record__score--error"]]:
                applicant.creditReport?.score === undefined,
            })}
            align="center"
            justify="center"
          >
            {applicant.creditReport?.score ?? (
              <IoWarningOutline
                size={24}
                color="var(--color-status-error-foreground)"
              />
            )}
          </Flex>
          <Flex className={styles["credit-record__info"]}>
            <Typography weight={6} size={0}>
              {`${applicant.firstName} ${applicant.lastName}`}
            </Typography>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};
