import { Chip, Flex, Typography } from "@nestoca/ui";
import { useGetApplication } from "@shared/api/hooks/applications";
import { formatPercentage } from "@shared/utils";
import { useTranslation } from "react-i18next";
import { BsCurrencyDollar } from "react-icons/bs";

import { DetailsHeading } from "./details-heading";
import styles from "./details.module.scss";

type Props = {
  applicationId: number;
};

export const Product = ({ applicationId }: Props) => {
  const { data: application } = useGetApplication({ id: applicationId });
  const { t } = useTranslation("dashboard");

  const productTypeLabel = application?.mortgage?.product?.type
    ? t(`table.productTypes.${application.mortgage.product.type}`)
    : "";

  const productTermLabel = application?.mortgage?.product?.term
    ? t(`table.productTerms.${application.mortgage.product.term}`)
    : "";

  const productLabel = `${productTypeLabel} ${productTermLabel}`.trim();

  return (
    <Flex
      direction="column"
      className={styles.block}
      gap={4}
      data-testid="details-block"
    >
      <DetailsHeading
        icon={<BsCurrencyDollar />}
        text={t("selectedProduct", { ns: "common" })}
      >
        {application && (
          <Chip
            label={t(`table.applicationTypes.${application?.type}`, {
              ns: "dashboard",
            })}
            className={styles["product__type"]}
          />
        )}
      </DetailsHeading>
      {application?.mortgage && (
        <Flex gap={3}>
          <Flex className={styles["product__rate"]}>
            {formatPercentage(application.mortgage.finalRate, {
              maximumFractionDigits: 3,
            })}
          </Flex>
          <Flex direction="column">
            {application.mortgage.product && (
              <Typography weight={6} className={styles["product__info"]}>
                {application.mortgage.product.name}
              </Typography>
            )}
            <Typography className={styles["product__info"]}>
              {productLabel}
            </Typography>
          </Flex>
        </Flex>
      )}
      {!application?.product && (
        <Typography weight={6} className={styles["product__info"]}>
          {t("noProductSelected", { ns: "applications" })}
        </Typography>
      )}
    </Flex>
  );
};
